@import "~bulma/sass/utilities/initial-variables.sass";

$black: #080808;
$text-black: #000000;
$white: #ffffff;
$second-white: #fbfbfb;
$text-white: #f2f2f2;
$grey: #9a9898;
$dark-grey: #454544;
$grey-deactive: #f3f3f3;
$light-grey: #c1c0c0;
$lighter-grey: #d8d8d8;

$green: #8ab832;
$blue: #172982;
$light-blue: #077CC0;
$red: #d33215;
$orange: #f3921f;
$light: #f0f0f0;

$primary: $blue;

$menu-width: 200px;

// 3. Import the rest of Bulma;
@import "~bulma/bulma.sass";
@import "~bulma-extensions/bulma-divider/dist/css/bulma-divider.sass";
@import "~bulma-extensions/bulma-checkradio/dist/css/bulma-checkradio.sass";
@import "~bulma-extensions/bulma-pageloader/dist/css/bulma-pageloader.sass";
@import "~bulma-extensions/bulma-timeline/dist/css/bulma-timeline.sass";
@import "~@creativebulma/bulma-collapsible";
@import "~@creativebulma/bulma-tooltip";
@import "~react-image-gallery/styles/scss/image-gallery.scss";

html {
  overflow-x: auto;
}

body {
  font-family: "Roboto", sans-serif;
}

.navbar {
  background-color: $light;

  img {
    height: 40px;
    width: 86px;
    max-height: 40px;
    max-width: 86px;
    margin: -5px;
    object-fit: contain;
  }

  .navbar-menu {
    margin-left: 1rem;

    .navbar-item {
      a {
        color: $blue;

        &:hover {
          color: $grey;
        }
      }

      &.active {
        background-color: #d3d3d3;
      }
    }
  }
}

.site-menu {
  .menu {
    width: $menu-width;
    height: calc(100vh - 3.25rem);
    background: $light;
    padding: 0.75em;
    position: fixed;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1;

    .menu-label {
      font-size: 0.85em;
      font-weight: bold;
    }

    .menu-list {
      font-size: 0.85em;

      a {
        &:hover {
          background-color: lighten($light-blue, 50);
        }

        &.is-active {
          background-color: $light-blue;

          &:hover {
            background-color: $light-blue;
          }
        }
      }
    }
  }

  #menu-left {
    width: $menu-width;
  }
}

.org-setting {
  textarea, pre {
    height: 400px;
    font-family: monospace;
    font-size: 0.75rem;
    padding: 0.75rem;
  }
}

.action-bar {
  // align-items: center;
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: flex-end;
  border-bottom: 1px solid lightgray;
  margin-bottom: 0.75rem;
  padding-bottom: 0.75rem;

  form {
    flex: 1;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  .action-bar-item {
    display: flex;
    padding: 0 0.75rem;

    &:last-child {
      padding-right: 0;
    }
  }

  .action-bar-export {
    width: 150px;
  }
}

.json-data {
  min-width: 200px;
  max-width: 300px;
}

.card {
  .card-content {
    padding: 0.75rem;
  }

  .media {
    .media-content {
      word-wrap: break-word;
      word-break: break-all;
    }

    &:not(:last-child) {
      margin-bottom: 0.75rem;
    }
  }
}

.modal-card {
  .modal-card-title {
    font-size: 1.2rem;
  }
}

.card-image {
  .image {
    text-align: center;

    img, video {
      max-height: 220px;
      object-fit: contain;
    }
  }
}

.common-data {
  border: 1px solid lightgrey;
  border-radius: 6px;
  padding: 0.75rem;

  .common-data-content {
    max-height: 250px;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.react-autosuggest__suggestions-container {
  position: absolute;
  z-index: 1000;
  background: white;
  width: 100%;
}

ul.react-autosuggest__suggestions-list {
  list-style: none;
  border: 1px solid lightgray;
  margin: 0;

  li.react-autosuggest__suggestion {
    padding: 0.5em 0.75em;
    margin-top: 0;
    border-bottom: 0.5px solid $light;

    &:hover {
      background-color: $light-blue;
      color: $white;
    }
  }
}

.datetime-wrapper {
  width: 100%;
}

.scenario-item {
  width: 100%;
	box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);

  .card-header {
    box-shadow: 0 1px 1px rgba(10, 10, 10, 0.1);
  }

  .photo-list-container {
    max-height: 350px;
    overflow-y: auto;
  }
}

.user-editor {
  max-width: 600px;
}

.is-center {
  display: flex;
  align-items: center;
}

.image {
  &.has-preview {
    cursor: pointer;
  }
}

.modal-card-foot {
  &.is-pulled-right {
    justify-content: flex-end;
  }
}

.token {
  word-wrap: break-word;
  word-break: break-all;
}

.token-actions {
  min-width: 100px;
}

.is-checkradio[type="radio"] + label, .is-checkradio[type="checkbox"] + label {
  padding-top: 0.1rem;
}

.bulma-delete-mixin {
  @include delete;
}

.bulma-loader-mixin {
  @include loader;
}

.image-gallery {
  width: 100%;
}
